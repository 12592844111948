require('../common');

/////////////////////////
// style
require('./bootstrap/dist/css/cerulean-bootstrap.css');
require('../../../vendor/kartik-v/bootstrap-tabs-x/css/bootstrap-tabs-x.css');
// require('../../../vendor/kartik-v/bootstrap-tabs-x/js/bootstrap-tabs-x'); <--- JS はリンクせずに使っている

/////////////////////////
// ライブラリ

// iFrameResize
const iFrameResize = require('iframe-resizer/src/iframeResizer');
window.iFrameResize = iFrameResize;
require('iframe-resizer/src/iframeResizer.contentWindow');

// PrismJS
const Prism = require('prismjs');
window.Prism = Prism;
require('prismjs/themes/prism.css');
const languages = require('./site/prism-lang');
for (let language of languages) {
    require('prismjs/components/prism-' + language + '.js')
}

/////////////////////////
// jQuery プラグイン

// bootbox は jQuery プラグインではあるけど、そのスタマイズはグローバルにある bootbox オブジェクトを使う。
// こうするしかしょうがない。
const bootbox = require('bootbox');
window.bootbox = bootbox;

require('lazyloadxt/dist/jquery.lazyloadxt');
require('lazyloadxt/dist/jquery.lazyloadxt.widget');

// at.js は jQuery に caret() が拡張されてる前提でできている。
require('jquery.caret');
require('at.js');
require('at.js/dist/css/jquery.atwho.css');

require('bootstrap-slider');
require('bootstrap-slider/dist/css/bootstrap-slider.css');

// drawer は中で require('jquery') してるので、別バンドルに入れると別の jQuery を拡張しちゃう。
// そのくせ IScroll クラスはグローバルに存在することをあてにしている。こうやるしかない。
require('jquery-drawer');
require('jquery-drawer/dist/css/drawer.css');
const IScroll = require('iscroll/build/iscroll-lite');
window.IScroll = IScroll;

/////////////////////////
// lib
require('../lib/emoji');
// 絵文字の定義に外部リンクも必要
require('../lib/mdtoolbox');
require('../lib/textarea-autosize/jquery.textarea_autosize');
require('../lib/ekko-lightbox-mod');
require('../lib/picture-upload/picture-upload');
