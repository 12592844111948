(function($) {
    $.fn.pictureUpload = function(options) {
        options = $.extend({
            uploadUrl: null,
            clearUrl: null,
            name: null,
            initialPreviewUrl: null,
            clearedPreviewUrl: null,
            uploadButtonLabel: 'アップロード',
            clearButtonLabel: 'クリア',
            clearConfirmMessage: '本当によろしいですか'
        }, options);

        this.each(function() {
            const $input = $(this);
            const $container = $('<div>').addClass('clearfix').insertAfter($input);

            // noinspection HtmlRequiredAltAttribute,RequiredAttributes
            const $preview = $('<img>').addClass('preview pull-left').css({
                width: 128,
                height: 128,
                background: '#ccc',
                marginRight: 10
            }).appendTo($container);
            if (options.initialPreviewUrl) {
                $preview.attr({
                    src: options.initialPreviewUrl
                });
            }

            const $spinner = $('<span>').addClass('fa-regular fa-spinner fa-spin').hide().appendTo($container);

            const $uploadButton = $('<button>').addClass('btn btn-primary').text(options.uploadButtonLabel).css({
                marginBottom: 4
            });
            $('<div>').append($uploadButton).append($spinner).appendTo($container);

            const $clearButton = $('<button>').addClass('btn btn-default').text(options.clearButtonLabel).css({});
            $('<div>').append($clearButton).appendTo($container);

            const $fileSelector = $('<input>').attr({
                type: 'file',
                accept: 'image/jpeg, image/png, image/gif, image/webp'
            }).css({
                display: 'none',
                opacity: 0
            }).appendTo($container);

            function updatePreview(url) {
                $preview.attr('src', url);
            }

            function removePreview() {
                if (options.clearedPreviewUrl) {
                    $preview.attr('src', options.clearedPreviewUrl);
                } else {
                    /*
                     * To fix safari issue, that the image remains shown despite the src attribute is set to null and removed from DOM element.
                     * Workaround: Set transparent 1 x 1 pixel image.
                     */
                    $preview.attr('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
                    //$preview.attr('src', null);
                }
            }

            function updateClearButton() {
                if ($.trim($input.val()) === "") {
                    $clearButton.addClass('disabled').prop('disabled', true);
                } else {
                    $clearButton.removeClass('disabled').prop('disabled', false);
                }
            }

            function changeButtonState(processing) {
                $uploadButton.prop('disabled', processing);
                $clearButton.prop('disabled', processing);
                processing ? $spinner.show() : $spinner.hide();
            }

            $uploadButton.on('click', function(){
                $fileSelector.show();
                setTimeout(function(){
                    $fileSelector.trigger('click');
                    setTimeout(function(){
                        $fileSelector.hide();
                    }, 1);
                }, 1);
                return false;
            });

            $clearButton.on('click', function(){
                if (options.clearUrl) {
                    yii.confirm(options.clearConfirmMessage, function(){
                        $.ajax({
                            url: options.clearUrl,
                            type: 'POST',
                            processData: false,
                            contentType: false,
                            dataType: 'json'
                        }).done(function( res ) {
                            changeButtonState(false);
                            if ( res.status ) {
                                if ( res.status === 'OK' ) {
                                    $input.val('');
                                    removePreview();
                                    updateClearButton();
                                    //input.replaceWith( input.clone(true) );
                                }
                                if ( res.status === 'error' ) {
                                    bootbox.alert(res.message);
                                }
                            } else {
                                bootbox.alert('通信エラー');
                            }
                        }).fail(function() {
                            changeButtonState(false);
                            bootbox.alert('通信エラー');
                            // console.log( 'ERROR', jqXHR, textStatus, errorThrown );
                        });
                    });
                } else {
                    $input.val('');
                    removePreview();
                    updateClearButton();
                }
                return false;
            });

            $fileSelector.on('change', function() {
                changeButtonState(true);

                const data = new FormData();
                data.append(options.name, $fileSelector.prop('files')[0]);
                $fileSelector.val(null);

                $.ajax({
                    url: options.uploadUrl,
                    type: 'POST',
                    data: data,
                    processData: false,
                    contentType: false,
                    dataType: 'json'
                }).done(function( res ) {
                    changeButtonState(false);
                    if ( res.status ) {
                        if ( res.status === 'OK' ) {
                            $input.val( res.name );
                            // noinspection JSUnresolvedVariable
                            updatePreview(res.url.thumbnail);
                            updateClearButton();
                            //input.replaceWith( input.clone(true) );
                        }
                        if ( res.status === 'error' ) {
                            bootbox.alert(res.message);
                        }
                    } else {
                        bootbox.alert('通信エラー');
                    }
                }).fail(function() {
                    changeButtonState(false);
                    bootbox.alert('通信エラー');
                    // console.log( 'ERROR', jqXHR, textStatus, errorThrown );
                });
            });

            updateClearButton();
        });
    };
})(jQuery);
