(function($) {
    const RESOURCE_DEF = {
        'svg': {
            folder: 'svg',
            ext: '.svg'
        },
        'png': {
            folder: '36x36',
            ext: '.png'
        }
    };

    const HTML_ENTITY_REPLACEMENT = {
        'a9': '&copy;',   // © copyright
        'ae': '&reg;',    // ® registered trademark
        '2122': '&trade;' // ™ trademark
    };

    let resourceType = 'svg';
    // Older Android browser is skipped because of bad SVG handling.
    const ua = window.navigator.userAgent;
    if (/Linux;.*Android(\s*(\d+)\.(\d+))?/i.test(ua)) {
        const majorVersion = parseInt(RegExp.$2);
        const minorVersion = parseInt(RegExp.$3);
        if (!(majorVersion >= 5 || (majorVersion === 4 && minorVersion >= 4) || /(Chrome|Firefox)/i.test(ua))) {
            resourceType = 'png';
        }
    }
    // Older Android browser is skipped because of bad SVG handling.

    $.fn.emojify = function() {
        this.each(function() {
            const emojiElement = $(this);
            const rawText = emojiElement.text();
            if (rawText.match(/[<>]/)) {
                return;
            }
            const replacement = twemoji.parse(rawText, {
                folder: RESOURCE_DEF[resourceType]['folder'],
                ext: RESOURCE_DEF[resourceType]['ext']
                // 絵文字を意図したマルCなどは絵文字として表示する。
                // 変換は <span class="emoji"> で囲まれた領域だけなので、素のマルCは
                // ここを通らない。また、入力補正ですでに &copy; などに変換されていると
                // 期待できるので、あえて変換避けをする必要もあまりない。
                // , callback: function (codepoint, options) {
                //     // if (HTML_ENTITY_REPLACEMENT.hasOwnProperty(codepoint)) {
                //     //    return false;
                //     // }
                //     return ''.concat(options.base, options.size, '/', codepoint, options.ext);
                // }
            });
            emojiElement.replaceWith(replacement);
        });
    };

    $.fn.inverseEmojify = function() {
        this.each(function() {
            const replace = function () {
                const text = $(this).val();
                let replaced = window._emoji.replaceToEmojiName(text, function (codepoint, name) {
                    if (HTML_ENTITY_REPLACEMENT.hasOwnProperty(codepoint)) {
                        return HTML_ENTITY_REPLACEMENT[codepoint];
                    }
                    return name;
                });
                if (replaced !== text) {
                    // if (replaced.match(/\ufe0f/)) {
                    //     console.log(replaced.split('').map(function (value) {
                    //         return value.charCodeAt(0).toString(16);
                    //     }).join('-'));
                    //     replaced = replaced.replace(/\ufe0f/g, '');
                    //     console.log(replaced.split('').map(function (value) {
                    //         return value.charCodeAt(0).toString(16);
                    //     }).join('-'));
                    // }
                    // https://github.com/twitter/twemoji/issues/275
                    // U+FE0Fの有無がTwemojiと現行のApple実装とで違うのでゴミが残った場合は消す
                    replaced = replaced.replace(/\ufe0f/g, '');
                    $(this).val(replaced);
                }
            };
            $(this).on('change blur', replace);
            $(this).parents('form').on('submit', replace);
        });
    };
})(jQuery);
