require('./framework-preset');
require('./default.css');

window.twemoji = require('twemoji').default;

// config/web-common.php で registerJsVar して与えられている
if (window.hasOwnProperty('_emoji_asset_root')) {
    window.twemoji.base = window._emoji_asset_root;
} else {
    window.twemoji.base = "/_assets/twemoji/";
}

require('./fonts-dynamic');
