module.exports = [
    // defaults
    // 'none', 'markup', 'xml', 'html', 'mathml', 'svg', 'css', 'clike', 'javascript', 'js',

    // デフォルトに含まれないけどロードしておかないと PHP 等のシンタックスでエラーになる
    'markup-templating',

    'bash', 'c', 'csharp', 'cpp', 'diff', 'erlang',
    'glsl', 'groovy', 'go', 'haskell', 'java', 'json', 'julia',
    'kotlin', 'latex', 'lua', 'makefile', 'matlab', 'objectivec', 'ocaml',
    'pascal', 'perl', 'php', 'python', 'r', 'ruby', 'rust',
    'sas', 'scala', 'scheme', 'sql', 'swift', 'yaml',

    // マイナー汎用
    'd', 'dart', 'elixir', 'nim',

    // 古典
    // 'ada', 'basic', 'eiffel', 'fortran', 'prolog', 'smalltalk', 'tcl',

    // MSシェルどうしたものか
    // 'batch', 'powershell',

    // 設定ファイル案外使う
    'ini', 'properties',

    // OCamlでいいか
    // 'fsharp'

    // Alt-JS/CSS
    'coffeescript', 'jsx', 'less', 'sass', 'scss', 'stylus', 'typescript',

    // HTMLテンプレート言語
    'haml', 'pug',
    // HTMLに埋めるテキストテンプレートだしいいか
    // 'django', 'smarty', 'twig',

    // マークアップ
    'markdown', 'rest',
    // 方言きついし用途なさそう
    // 'wiki' ,'textile',

    // 特殊
    // 'docker', 'gherkin', 'protobuf', 'vim',
];
