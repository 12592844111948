/*
 * Markdownテキストの入力支援ツールボックス。
 *
 * 使い方：
 * jQuery('textfields').markdown_toolbox( options );
 *
 * オプション：
 * style : ツールボックスのdiv要素に適用するスタイル値。
 * autoHide : trueならばテキストボックスへのフォーカスイン／フォーカスアウトで自動的に表示／非表示する。
 * size : ボタンサイズ（クラス名）
 */

import SparkMD5 from 'spark-md5';
import EmojiPalette from "../../frontend/site/js/emoji/emoji-palette";

/**
 * @param {jQuery} $
 */
(function($){
    const UPLOADER_MAX_FILE_NUMBER = 10;
    const UPLOADER_MAX_FILE_SIZE = 1000 * 1000 * 20;
    const UPLOADER_MIMETYPE_PATTERN = /image\/(png|jpeg|jpg|gif|webp)/;

    // プラグインの定義
    $.fn.markdown_toolbox = function(options) {

        const setting = $.extend({
            style: 'margin-top:5px; margin-bottom:5px;',
            autoHide: true,
            size: 'btn-xs', // btn-lg | btn-sm | btn-xs
            previewFunction: null,
            uploaderUrl: null,
            externalResourceFetchUrl: null,
            dialogAreaSelector: null, // プレビューとか対話入力を入れる領域のセレクタ。
            embeddableServiceGuideSelector: null,
            singleLineStyle: false,
            onlyForPreview: false,
            enableFullscreen: false,
            emojiImageUrl: '',
        }, options);
        
        if (setting.previewFunction === null) {
            setting.previewFunction = function (source, callback) {
                // marked が読み込まれていなければ自動的に読み込む。
                //noinspection JSUnresolvedVariable
                if (typeof (marked) != 'function') {

                    const _fallback_marked_js = 'https://cdn.rawgit.com/chjj/marked/master/lib/marked.js';

                    const s1 = document.createElement('script');
                    s1.type = 'text/javascript';
                    s1.src = _fallback_marked_js;
                    s1.onload = function () {
                        console.log('_fallback_marked_js is loaded!');
                    };
                    document.body.appendChild(s1);
                }

                //noinspection JSUnresolvedVariable,JSUnresolvedFunction,JSUnusedGlobalSymbols
                marked.setOptions({
                    gfm: true,
                    tables: true,
                    breaks: false,
                    pedantic: false,
                    sanitize: false, // true にするとhtmlタグが無効になる
                    smartLists: true,
                    smartypants: false,
                    langPrefix: 'language-',
                    highlight: function (code /*, lang*/) {
                        return code;
                    }
                });
                //noinspection JSUnresolvedFunction
                let unsafeHtml = marked(source);
                const bgUrl = "url('" + setting.emojiImageUrl + "$1.svg')";
                // noinspection RegExpSimplifiable
                unsafeHtml = unsafeHtml.replace(new RegExp(':([0-9a-z\\-]+):', 'g'),
                    '<span class="twa" style="background-image: ' + bgUrl + ';"></span>');
                callback(unsafeHtml);
            };
        }
        // --------------- ボタンの定義 ---------------

        this.each(function(index, element){
            /** @type jQuery textfield */
            const textfield = $(element);
            const size = setting.size;

            interceptUndoRedo(textfield, 10);

            function btn_emoji(textfield) {

                const emojiReactionPalette = EmojiPalette.getInstance(
                    'for-comment',
                    'comment',
                    setting.emojiImageUrl,
                    ['top', 'left'],
                    function (emoji, target, palette) {
                        _apply(textfield, {
                            placeholder : '',
                            before : '',
                            after : ':' + emoji + ':'
                        });
                        palette.close();
                    },
                    () => textfield.outerWidth()
                );

                const codeNum = _emoji.emojiname2codepoint['smile']
                const icon = $('<span class="twa"></span>')
                    .css({'background-image': `url(${setting.emojiImageUrl + codeNum}.svg)`})

                const paletteButtonRoot = $('<div class="comment-action-button emoji-palette-button"></div>')
                const paletteButton = $('<button class="btn btn-default btn-xs" type="button" tabindex="-1" title="絵文字">')
                    .append(icon)
                    .on('click', function () {
                        emojiReactionPalette.toggle(paletteButtonRoot);
                    })
                ;
                paletteButtonRoot.append(paletteButton)

                return paletteButtonRoot;
            }
            function btn_bold() {
                return _simple_button(size, textfield, '太字', '<i class="fa-solid fa-bold"></i>', { placeholder:'太字', before:'**', after:'**' });
            }
            function btn_italic() {
                return _simple_button(size, textfield, 'イタリック', '<i class="fa-solid fa-italic"></i>', { placeholder:'イタリック', before:'*', after:'*' });
            }
            function btn_color() {
                return _dropdown(size, '文字色', $('<i class="fa-solid fa-font"></i>').css('color','red'), [
                    $('<li>').append(
                        _color_palette(function(color){
                            _apply(textfield, {placeholder:'色付き文字', before:'%%{fg:'+color+'}', after:'%%'});
                        })
                    )
                ], Math.min(Math.max(textfield.width(), 250), 750) - 49);
            }
            function btn_bgcolor() {
                return _dropdown(size, '背景色', $('<i class="fa-solid fa-font"></i>').css({
                    'color':'black',
                    'background-color':'yellow'
                }), [
                    $('<li>').append(
                        _color_palette(function(color){
                            _apply(textfield, {placeholder:'背景色付き文字', before:'%%{bg:'+color+'}', after:'%%'});
                        })
                    )
                ], Math.min(Math.max(textfield.width(), 250), 750) - 49);
            }
            function btn_fontsize() {
                return _dropdown(size, '文字サイズ', '<i class="fa-solid fa-text-height"></i>', [
                    _dropdown_item(textfield, '16pt', '16pt', {placeholder:'サイズ指定文字', before:'%%{size:16pt}', after:'%%'}),
                    _dropdown_item(textfield, '14pt', '14pt', {placeholder:'サイズ指定文字', before:'%%{size:14pt}', after:'%%'}),
                    _dropdown_item(textfield, '12pt', '12pt', {placeholder:'サイズ指定文字', before:'%%{size:12pt}', after:'%%'}),
                    _dropdown_item(textfield, '10pt', '10pt', {placeholder:'サイズ指定文字', before:'%%{size:10pt}', after:'%%'}),
                    _dropdown_item(textfield, '8pt', '8pt',   {placeholder:'サイズ指定文字', before:'%%{size:8pt}',  after:'%%'})
                ], null);
            }
            function btn_strike() {
                return _simple_button(size, textfield, '取り消し線', '<i class="fa-solid fa-strikethrough"></i>', { placeholder:'削除テキスト', before:'~~', after:'~~' });
            }
            function btn_underline() {
                return _simple_button(size, textfield, '下線', '<i class="fa-solid fa-underline"></i>', {placeholder:'下線付きテキスト', before:'++', after:'++'});
            }
            function btn_img() {
                return _image_button(size, textfield, '画像（URL指定）', '<i class="fa-regular fa-image"></i>', '画像のURLを入力');
            }
            function btn_upload() {
                return _uploader_button(size, textfield, '画像アップロード', '<i class="fa-solid fa-upload"></i>', 'アップロードする画像を選択', setting.uploaderUrl, setting.dialogAreaSelector);
            }
            function btn_embed() {
                return _embedder_button(size, textfield, '外部コンテンツ埋め込み', '<i class="fa-solid fa-film"></i>', '埋め込みたい外部コンテンツの URL', setting.externalResourceFetchUrl, setting.embeddableServiceGuideSelector);
            }
            function btn_link() {
                return _link_button(size, textfield, 'リンク', '<i class="fa-solid fa-link"></i>', 'リンクのURLを入力', 'https://www.example.com');
            }
            function btn_heading() {
                return _dropdown(size, '見出し', '<i class="fa-solid fa-heading"></i>', [
                    _dropdown_item(textfield, '大見出し(H1)', '大', { placeholder:'大見出し', before:'# ', eachLine:true }),
                    _dropdown_item(textfield, '中見出し(H2)', '中', { placeholder:'中見出し', before:'## ', eachLine:true }),
                    _dropdown_item(textfield, '小見出し(H3)', '小', { placeholder:'小見出し', before:'### ', eachLine:true }),
                    _dropdown_item(textfield, '極小見出し(H4)', '極小', { placeholder:'極小見出し', before:'#### ', eachLine:true })
                    // _dropdown_item(textfield, 'H5', 'H5', { placeholder:'見出し5', before:'##### ', eachLine:true }),
                    // _dropdown_item(textfield, 'H6', 'H6', { placeholder:'見出し6', before:'###### ', eachLine:true })
                ], null);
            }
            function btn_ul() {
                return _simple_button(size, textfield, 'リスト', '<i class="fa-solid fa-list-ul"></i>', { placeholder:'リスト', before:'- ', eachLine:true });
            }
            function btn_ol() {
                return _simple_button(size, textfield, '数字リスト', '<i class="fa-solid fa-list-ol"></i>', { placeholder:'リスト', before:'1. ', eachLine:true });
            }
            function btn_blockquote() {
                return _simple_button(size, textfield, '引用', '<i class="fa-solid fa-quote-left"></i>', { placeholder:'引用テキスト', before:'> ', eachLine:true });
            }
            //function btn_code() {
            //    return _simple_button(size, textfield, 'コード', _fa('code'), { placeholder:'コード', before:'`', after: '`' });
            //}
            //function btn_multilineCode() {
            //    return _simple_button(size, textfield, '複数行コード', _fa('code'), { placeholder:'複数行コード', before:'\n```\n', after: '\n```\n' });
            //}
            function btn_table() {
                return _simple_button(size, textfield, '表', '<i class="fa-solid fa-table"></i>', { placeholder:'', before:'', after: '\n| 列名１    | 列名２    |\n| -------- | -------- |\n| セル      | セル      |\n| セル      | セル      |\n\n' });
            }
            function btn_aa() {
                return _simple_button(size, textfield, 'アスキーアート', 'AA', { placeholder:'', before:'<pre class="aa">\n', after:'\n</pre>' });
            }
            function btn_ruby() {
                // noinspection XmlDeprecatedElement,HtmlDeprecatedTag
                return _simple_button(size, textfield, 'ルビ', 'R', {
                    placeholder: '漢字',
                    before: '<ruby><rb>',
                    after: '</rb><rp>(</rp><rt>ルビ</rt><rp>)</rp></ruby>',
                    unselect: true,
                    shiftSelection: { start: -24, end:-22 }
                });
            }
            function btn_collapse() {
                return _simple_button(size, textfield, '折りたたみ','<i class="fa-regular fa-square-caret-down" aria-hidden="true"></i>', function(){
                    const sPlaceholder = 'コンテンツ';
                    let sBefore = '[ラベル:---\n';
                    const sAfter = '\n---]\n';
                    let shiftSelStart = 1;
                    let sBody = textfield.selection('get');
                    if (sBody === '') {
                        sBody = sPlaceholder;
                    }
                    const p = textfield.selection('getPos').start - 1;
                    if (p > 0 && textfield.val().charAt(p) !== "\n") {
                        sBefore = "\n" + sBefore;
                        shiftSelStart += 1;
                    }
                    _apply(textfield, {
                        placeholder: sPlaceholder,
                        before: sBefore,
                        after: sAfter,
                        shiftSelection: { start: shiftSelStart, end: 0 - sAfter.length - sBody.length - 5 }
                    });
                });
            }
            function btn_preview() {
                return _preview(textfield, size, setting.previewFunction, setting.dialogAreaSelector);
            }
            function btn_fullscreen() {
                return _fullscreen(textfield, size, setting.dialogAreaSelector);

            }
            function btn_more(optional) {
                return _simple_button(size, textfield, 'その他のツール', '...', function(){
                    optional.toggle();
                });
            }

            // --------------- ツールボックスを生成する    ---------------
            function initialize_toolbox() {

                const g_resource = [btn_link(), btn_img(), btn_embed()];
                if (setting.uploaderUrl) g_resource.push(btn_upload());

                const g_multiline = setting.singleLineStyle ? null :
                    [btn_heading(), btn_ul(), btn_ol(), btn_blockquote(), btn_aa(), btn_ruby(), btn_table(), btn_collapse()];

                /** @type jQuery toolbar2 - ２段目ツールバー */
                const toolbar2 = _toolbar(setting.style, setting.onlyForPreview ? [] : [g_resource, g_multiline]);

                /* １段目ツールバーに入れるボタングループ */
                const g_inline = _group(setting.onlyForPreview ? [] : [btn_emoji(textfield), btn_color(), btn_bgcolor(), btn_fontsize(), btn_bold(), btn_italic(), btn_strike(), btn_underline()]);
                const g_more = _group([btn_more(toolbar2)]);
                const g_preview = _group(setting.enableFullscreen ? [btn_preview(), btn_fullscreen()] : [btn_preview()]);

                /** @type {jQuery} １段目ツールバー */
                const toolbar1 = _toolbar(setting.style, [g_inline, g_more, g_preview]);

                /** 横幅に入る限り１段目ツールバーに入れる。（入らないもののみ２段目ツールバーに残す。） */
                function autosize() { // サイズ調整
                    let width = 0;
                    toolbar1.find('>.btn-group').each(function(){
                        width += $(this).outerWidth(true);
                    });
                    let skip = 0;
                    toolbar2.find('>.btn-group').each(function(){
                        const widthToBe = width + $(this).outerWidth(true);
                        if(widthToBe < textfield.outerWidth(true)){
                            g_more.before($(this));
                            width = widthToBe;
                        } else {
                            skip++;
                        }
                    });
                    if (skip===0) {
                        g_more.remove();
                        toolbar2.remove();
                    } else {
                        toolbar2.hide();
                    }
                }

                textfield.after(_toolbox(toolbar1, toolbar2));
                autosize();
                textfield.trigger('initialized');
            }

            if (setting.autoHide === true) {
                let initialized = false;
                textfield.focusin(function(){
                    if (!initialized && !textfield.attr('readonly') && !textfield.attr('disabled')) {
                        initialized = true;
                        initialize_toolbox();
                    }
                });
            } else {
                initialize_toolbox();
            }

        });

        this.parents('form').on('submit', function(){
            $(setting.dialogAreaSelector).find('>.markdown-toolbox-dialog').slideUp();
        });

        return this;
    };


    // --------------- jQueryオブジェクトを生成する関数 ---------------

    /** @return {jQuery} */
    function _toolbox(groups, optGroups) {
        return $('<div>')
            .addClass('markdown_toolbox')
            .append(groups)
            .append(optGroups);
    }
    /** @return {jQuery} */
    function _toolbar(style, groups) {
        const toolbar = $('<div>')
            .addClass('btn-toolbar')
            .attr('role', 'toolbar')
            .attr('style', style);
        $.each(groups, function(idx, val){
            if (val==null) return;
            if (val.jquery) {
                toolbar.append(val);
            } else if($.isArray(val)) {
                toolbar.append(_group(val));
            }
        });
        return toolbar;
    }
    /**
     * @param {Array} val
     * @returns {jQuery}
     */
    function _group(val) {
        const group = $('<div>').addClass('btn-group');
        $.each(val, function(idx, vv){
            group.append(vv);
        });
        return group;
    }

    /** @return {jQuery} */
    function _button(size, tooltip, inner, func) {
        return $('<button>').addClass('btn btn-default').addClass(size)
            .attr('type', 'button')
            .attr('tabindex', '-1')
            .attr('title', tooltip)
            //.attr('data-toggle','tooltip') // TOOLTIP DISABLED
            .attr('data-placement','auto')
            .append(inner)
            .click(func);
    }

    /**
     * シンプルなボタン
     * @param {String}          size
     * @param {jQuery}          textfield
     * @param {String}          tooltip
     * @param {String|jQuery}   inner
     * @param {Object|function} options
     * @returns {jQuery}
     * @private
     */
    function _simple_button(size, textfield, tooltip, inner, options) {
        return _button(size, tooltip, inner, function(e){
            e.preventDefault();
            if (typeof options == 'function') {
                options();
            } else {
                _apply(textfield, options);
            }
        });
    }

    /**
     * ボタンのハンドラとして使える、パネルを出す関数を返す
     * @param {String}     dialogTitle パネルのタイトルバー文字列
     * @param {jQuery}     bodyContent パネルのメイン部分DOM
     * @param {jQuery}     footerContent パネルのフッタ部分DOM
     * @param {Function}   onShownAction 表示されたときのアクション
     * @param {Function}   onOkAction OKされたときのアクション
     * @returns {Function} return
     * @private
     */
    function _modal_button_handler(dialogTitle, bodyContent, footerContent, onShownAction, onOkAction) {
        const _modal = $('<div>').addClass('modal fade app-modal').attr('data-backdrop', 'false').appendTo('body');

        function _close() {
            _modal.modal('hide');
        }

        function _ok(b) {
            onOkAction(function(){
                _close();
            }, b);
        }

        // 標準の backdrop の代わり
        _modal.on('click',function() {
            _close();
        });

        const _dialog = $('<div>').addClass('modal-dialog').append(
            $('<div>').addClass('modal-content').append(
                $('<div>').addClass('modal-header').append(
                    $('<h4>').addClass('modal-title').text(dialogTitle)
                )
            ).append(
                $('<div>').addClass('modal-body').append(bodyContent)
            ).append(
                $('<div>').addClass('modal-footer').append(footerContent).append(
                    $('<div>').addClass('pull-right').append(
                        $('<button>').addClass('btn btn-sm btn-primary').text('OK').on('click', function (e) {
                            e.preventDefault();
                            _ok(this);
                        })
                    ).append(
                        $('<button>').addClass('btn btn-sm btn-default').text('キャンセル').on('click', function (e) {
                            e.preventDefault();
                            _close();
                        })
                    )
                )
            )
        ).appendTo(
            _modal.on('shown.bs.modal', function () {
                onShownAction();
            })
        ).on('click', function (e) {
            e.stopPropagation();
        });

        return function(e) {
            e.preventDefault();
            _modal.modal('show');
            const coordinateDialogHorizontalPosition = function ($toolbar, $dialog) {
                const toolbarTop = $toolbar.offset().top;
                const scrollPosition = $(window).scrollTop();
                const dialogHeight = $dialog.outerHeight(true);
                const hPos = Math.max(0, toolbarTop - scrollPosition - dialogHeight);
                $dialog.css('top', hPos + 'px');
            };
            coordinateDialogHorizontalPosition($(this), _dialog);
        };
    }

    /**
     * リンクURL挿入パネルを出すボタン
     * @param {String}          size        ボタンサイズを表すクラス名
     * @param {jQuery}          textfield   操作対象テキストフィールドのjQueryオブジェクト
     * @param {String}          tooltip     ボタンのツールチップ
     * @param {String|jQuery}   inner       ボタンの中身（StringまたはjQueryオブジェクト）
     * @param {String}          message     パネルのタイトル部分に表示するメッセージ
     * @param {String}          example     placeholderにセットする値
     * @returns {jQuery} return             button要素のjQueryオブジェクト
     * @private
     */
    function _link_button(size, textfield, tooltip, inner, message, example) {
        const _input = $('<input>').addClass('form-control input-sm').attr('placeholder', example);
        const _checkbox = $('<input>').attr('type', 'checkbox');

        const _footer = $('<div>').addClass('pull-left text-left').append(
            $('<div>').addClass('checkbox').css('margin-top', '0').append(
                $('<label>').append(_checkbox).append(' HTMLタグ')
            )
        ).append(
            $('<div>').addClass('text-muted').css('margin-bottom', '10px').text('外部サイトは新規タブで開きます。')
        );

        return _button(size, tooltip, inner, _modal_button_handler(
            message, _input, _footer,
            function() {
                _input.focus();
            },
            function(next) {
                const v = _input.val();
                if (v) {
                    if (_checkbox.is(':checked')) {
                        _apply(textfield, { placeholder:'リンク', before:'<a href="' + v + '">', after:'</a>' });
                    } else {
                        _apply(textfield, { placeholder:'リンク', before:'[', after:'](' + v + ')' });
                    }
                    _input.val('');
                }
                next();
            }
        ));
    }

    /**
     * 画像URL挿入パネルを出すボタン
     * @param {String}          size        ボタンサイズを表すクラス名
     * @param {jQuery}          textfield   操作対象テキストフィールドのjQueryオブジェクト
     * @param {String}          tooltip     ボタンのツールチップ
     * @param {String|jQuery}   inner       ボタンの中身（StringまたはjQueryオブジェクト）
     * @param {String}          message     パネルのタイトル部分に表示するメッセージ
     * @returns {jQuery} return             button要素のjQueryオブジェクト
     * @private
     */
    function _image_button(size, textfield, tooltip, inner, message) {
        const _input = $('<input>').addClass('form-control input-sm').attr('placeholder', '//example.com/image.jpg');
        const _checkbox_big = $('<input>').attr('type', 'checkbox');
        const _checkbox_html = $('<input>').attr('type', 'checkbox');

        const _footer = $('<div>').addClass('pull-left text-left').append(
            $('<div>').addClass('checkbox').css('margin-top', '0').append(
                $('<label/>').append(_checkbox_big).append(' 本文上で最大サイズ表示')
            )
        ).append(
            $('<div>').addClass('checkbox').append(
                $('<label/>').append(_checkbox_html).append(' HTMLタグ')
            )
        );

        return _button(size, tooltip, inner, _modal_button_handler(
            message, _input, _footer,
            function() {
                _input.focus();
            },
            function _ok(next) {
                const v = _input.val();
                if (v) {
                    _apply(textfield, _checkbox_big.is(':checked')
                            ? _checkbox_html.is(':checked')
                                ? { placeholder:'画像', before:'<img src="' + v + '" alt="', after:'">\n', unselect:false }
                                : { placeholder:'画像', before:'![', after:'](' + v + ')\n', unselect:false }
                            : _checkbox_html.is(':checked')
                                ? { placeholder:'画像', before:'<a href="' + v + '" target="_blank"><img src="' + __small_img_url(v) + '" alt="', after:'"/></a>\n', unselect:false }
                                : { placeholder:'画像', before:'?[', after:'](' + v + ')\n', unselect:false }
                    );
                    _input.val('');
                }
                next();
            }
        ));
    }

    /**
     * アップローダーの画像URLパターンに合致するなら /s/ 付きのサムネ用URLにする
     * @param {String} src
     * @private
     */
    function __small_img_url(src) {
        const pattern = /^(\/\/pic\.zawazawa\.jp\/(dev_)?files\/.*\/)([^\/]*)$/;
        const matches = pattern.exec(src);
        if (matches != null) {
            return matches[1] + 's/' + matches[3];
        }
        return src;
    }

    /**
     * 埋め込みコンテンツ挿入パネルを出すボタン
     * @param {String}          size        ボタンサイズを表すクラス名
     * @param {jQuery}          textfield   操作対象テキストフィールドのjQueryオブジェクト
     * @param {String}          tooltip     ボタンのツールチップ
     * @param {String|jQuery}   inner       ボタンの中身（StringまたはjQueryオブジェクト）
     * @param {String}          message     パネルのタイトル部分に表示するメッセージ
     * @param {String}          externalResourceFetchUrl    埋め込みコード先行取得の Endpoint URL
     * @param {String}          embeddableServiceGuideSelector    埋め込み可能サービスの説明文字列
     * @returns {jQuery} return             button要素のjQueryオブジェクト
     * @private
     */
    function _embedder_button(size, textfield, tooltip, inner, message, externalResourceFetchUrl, embeddableServiceGuideSelector) {
        const _input = $('<input>').addClass('form-control input-sm').attr('placeholder', 'コンテンツの URL (パーマリンク)');
        const _alert = $('<div>').css('margin', '10px 0 0').hide();

        function _alert_danger(text) {
            _alert.attr('class', 'alert alert-danger').text(' ' + text).prepend('<i class="fa-solid fa-triangle-exclamation"></i>').show();
        }
        function _alert_progress(text) {
            _alert.attr('class', 'alert alert-success').text(' ' + text).prepend('<i class="fa-solid fa-spinner fa-spin"></i>').show();
        }
        function _alert_clear() {
            _alert.text('').hide();
        }

        const _body = $('<div>').append(_input).append(_alert);
        const _footer = $('<div>').addClass('pull-left').append($(embeddableServiceGuideSelector).html());

        return _button(size, tooltip, inner, _modal_button_handler(
            message, _body, _footer,
            function() { // onShownAction
                _input.focus();
            },
            function(_closeFunc, _okBtn) { // onOkAction
                _alert_clear();

                const v = _input.val();
                if (v) {
                    $(_okBtn).addClass('disabled');

                    const delay = setTimeout(function () {
                        _alert_progress('埋め込みコードを確認中です。');
                    }, 2000);

                    $.ajax({
                        url: externalResourceFetchUrl,
                        data: {url: v},
                        cache: false,
                        dataType: 'json',
                        success: function (data) {
                            if (!!data.status && data.status === 'ok') {
                                _alert_clear();
                                _apply(textfield, { placeholder:'外部コンテンツ', before:'@[', after:'](' + v + ')\n', unselect:false });
                                _input.val('');
                                _closeFunc();
                            } else if (!!data.status && data.status === 'error' && !!data.message) {
                                _alert_danger(data.message);
                            } else {
                                _alert_danger('埋め込みコードを確認できませんでした。');
                            }
                        },
                        error: function () {
                            _alert_danger('埋め込みコードを確認できませんでした。');
                        }
                    }).always(function(){
                        $(_okBtn).removeClass('disabled');
                        clearTimeout(delay);
                    });
                }
            }
        ));
    }

    /**
     * 画像アップローダーを呼び出すボタン
     * @param {String} size                 ボタンサイズを表すクラス名
     * @param {jQuery} textfield            操作対象テキストフィールドのjQueryオブジェクト
     * @param {String} tooltip              ボタンのツールチップ
     * @param {String|jQuery} inner         ボタンの中身（StringまたはjQueryオブジェクト）
     * @param {String} message              パネルのタイトル部分に表示するメッセージ
     * @param {String} uploaderUrl          アップローダーの Endpoint URL
     * @param {String} dialogAreaSelector   パネルの注入先要素のcssセレクタ
     * @returns {jQuery} return             button要素のjQueryオブジェクト
     * @private
     */
    function _uploader_button(size, textfield, tooltip, inner, message, uploaderUrl, dialogAreaSelector) {
        function _close(panel, progress) {
            progress.attr('aria-valuenow', '0').css('width', '0');
            panel.slideUp();
            panel.find('.alert-danger, .alert-warning').remove();
        }
        /** @returns {jQuery} */
        function _alert(optClass, child) {
            return $('<div>').addClass('alert').addClass(optClass).css('margin','10px 0 0').append(
                $('<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span>').on('click', function(e){
                    e.preventDefault();
                    //$(this).parent().remove();
                })
            ).append(child);
        }
        /** @returns {String} */
        function _id_for_upload(fileIdentity) {
            return 'uploader-' + encodeURIComponent(fileIdentity);
        }

        const fileToBeUploaded = {}; // MD5ハッシュ値をキー、Fileオブジェクトを値に持つ連想配列
        /** 連想配列とDOMからハッシュに合致するエントリを除去する */
        function _uploader_remove(hash, name) {
            if (hash in fileToBeUploaded) {
                delete fileToBeUploaded[hash];
            } else if (!!name && name in fileToBeUploaded) {
                delete fileToBeUploaded[name];
            } else {
                console.error(hash + 'に対応する送信準備ファイルがありません。');
            }
            let elm = document.getElementById(_id_for_upload(hash));
            if (elm == null && !!name) {
                elm = document.getElementById(_id_for_upload(name));
            }
            if (elm) {
                $(elm).remove();
            } else {
                console.error(hash + 'に対応するDOM要素がありません。');
            }
        }
        /** 連想配列とDOMにファイルのエントリを追加する。 */
        function _uploader_prepair(display, files) {
            function _add_image(fileIdentity, file, src) {
                if (Object.keys(fileToBeUploaded).length >= UPLOADER_MAX_FILE_NUMBER) {
                    // コールバック前にすり抜けたやつを再チェック
                    display.prepend(_alert('alert-warning', '一度に送信可能な最大のファイル数を超えています。'));
                    return;
                }
                fileToBeUploaded[fileIdentity] = file;
                // noinspection HtmlRequiredAltAttribute,RequiredAttributes
                _display.append(
                    $('<div>').attr('id', _id_for_upload(fileIdentity)).addClass('alert col-xs-4 col-sm-3 col-md-3 col-lg-2').css('margin', '10px 0 0').append(
                        $('<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span>').on('click', function (e) {
                            e.preventDefault();
                            _uploader_remove(fileIdentity);
                        })
                    ).append(
                        $('<img/>').addClass('img-responsive img-thumbnail').attr('src', src).css('background-color', 'white').css('width', '100%').on('error', function(){
                            display.prepend( _alert('alert-warning', file.name + ' のサムネイルを表示できません。アップロードするとサーバー側でファイル形式を確認します。') );
                        })
                    ).append(
                        $('<span/>').addClass('message')
                    )
                );
            }
            $.each(files, function(idx, file) {
                if (file.size > UPLOADER_MAX_FILE_SIZE) {
                    display.prepend( _alert('alert-warning', file.name + 'はファイルサイズが大きすぎます。') );
                } else if ( !!file.type && UPLOADER_MIMETYPE_PATTERN.test(file.type) === false ) {
                    // Android 標準ブラウザなど file.type が取れない環境もあるので、ある場合だけチェック
                    display.prepend( _alert('alert-warning', file.name + 'は受付可能な形式ではありません。') );
                } else if (Object.keys(fileToBeUploaded).length >= UPLOADER_MAX_FILE_NUMBER) {
                    // FileReaderのコールバック前にすり抜ける可能性はあるので、後でもう一回チェック要。
                    display.prepend(_alert('alert-warning', '一度に送信可能な最大のファイル数を超えています。'));
                } else {
                    // 処理時間が掛かるスマホのために状況を表示
                    const info = _alert('alert-info',
                        $('<span/>').append('<i class="fa-solid fa-spinner"></i>').append(' 読み込み中：' + file.name + ' (' + file.size + ' bytes)')
                    ).appendTo(display);

                    // ファイルをバイト配列として読んでハッシュ計算
                    const arrayReader = new FileReader();
                    arrayReader.onloadend = function() {
                        /*
                         * Wii U ブラウザの挙動：
                         * - FileReader の read 系メソッドで file を読むと FileError (code 1 = NOT_FOUND_ERR) になる。
                         * - createObjectURL(file) で URL は作れるけれども、実際にその URL からデータを読み込むことはできない。
                         *
                         * 生成された File オブジェクトが、リモートへ送信は可能だが、ローカルでは読めない制約がかかってる様子。
                         * ハッシュ計算やサムネ表示ができなくても、とりあえずアップロードだけはできるようにしておく。
                         */
                        let fileIdentity;
                        const result = arrayReader.result;
                        if (result instanceof ArrayBuffer) {
                            fileIdentity = SparkMD5.ArrayBuffer.hash(result, false);
                        } else {
                            fileIdentity = file.name;
                        }

                        if (fileIdentity in fileToBeUploaded) {
                            display.prepend( _alert('alert-warning', file.name + 'は既に選択されています。') );
                            info.remove();
                        } else {
                            if (createObjectURL) {
                                _add_image(fileIdentity, file, createObjectURL(file));
                                info.remove();
                            } else {
                                const urlReader = new FileReader();
                                urlReader.onloadend = function() {
                                    if (urlReader.error) {
                                        display.prepend( _alert('alert-warning', file.name + 'のデータ生成エラー') );
                                    } else {
                                        _add_image(fileIdentity, file, urlReader.result);
                                    }
                                    info.remove();
                                };
                                urlReader.readAsDataURL(file);
                            }
                        }
                    };
                    arrayReader.readAsArrayBuffer(file);
                }
            });
        }
        /** 連想配列に保持されたファイルのエントリをAJAXリクエストで送信する。 */
        function _uploader_upload(panel, progress, display, checkbox_big, checkbox_link, checkbox_html) {
            //noinspection JSUnresolvedFunction
            const label = textfield.selection('get');

            function _progress_set(value, active) {
                progress.attr('aria-valuenow', value).css('width', value+'%');
                if (active===true) progress.addClass('active');
                if (active===false) progress.removeClass('active');
            }
            // ajax()に渡して送信するデータ
            const data = new FormData();
            let count = 0;
            $.each(fileToBeUploaded, function(fileIdentity, file){
                data.append('file[]', file);
                count++;
            });
            // 送信するものがなければ閉じて終了
            if ( count===0 ) {
                _close(panel, progress);
                return;
            }
            // UIを送信前の状態にセット
            _progress_set(0, true);
            panel.find('button').attr('disabled', true);
            // 送信処理を開始
            $.ajax({
                url: uploaderUrl,
                type: 'POST',
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                xhr: function(){
                    const XHR = $.ajaxSettings.xhr();
                    if(XHR.upload){
                        XHR.upload.addEventListener('progress', function(e){
                            _progress_set( e.loaded / e.total * 90 );
                        }, false);
                    }
                    return XHR;
                },
                success: function(data){
                    _progress_set(100, false);
                    panel.find('button').attr('disabled', false);
                    let count_ok = 0;
                    let count_error = 0;
                    $.each(data, function(idx, item){
                        if (item.status && item.status === 'OK') {
                            count_ok++;
                            //noinspection JSUnresolvedVariable

                            const img_large = item.url.large;
                            const img_small = item.url.small;

                            const img_alt = (label ? label : '画像') + count_ok;
                            let options;
                            if (checkbox_big.is(':checked')) {
                                options = checkbox_html.is(':checked')
                                    ? { placeholder:img_alt, before:'<img src="' + img_large + '" alt="', after:'">\n', unselect:true }
                                    : { placeholder:img_alt, before:'![', after:'](' + img_large + ')\n', unselect:true };
                            } else if (checkbox_link.is(':checked')) {
                                options = checkbox_html.is(':checked')
                                    ? { placeholder:img_alt, before:'<a href="' + img_large + '">', after:'</a>\n', unselect:true }
                                    : { placeholder:img_alt, before:'[', after:'](' + img_large + ')\n', unselect:true };
                            } else {
                                options = checkbox_html.is(':checked')
                                    ? { placeholder:img_alt, before:'<a href="' + img_large + '"><img src="' + img_small + '" alt="', after:'"/></a>\n', unselect:true }
                                    : { placeholder:img_alt, before:'?[', after:'](' + img_large + ')\n', unselect:true };
                            }

                            _apply(textfield, options);
                            _uploader_remove(item.hash, item.name);
                        } else {
                            count_error++;
                            let target = document.getElementById(_id_for_upload(item.hash));
                            if (target == null && !!item.name) {
                                target = document.getElementById(_id_for_upload(item.name));
                            }
                            if (target) {
                                $(target).addClass('alert-danger').find('.message').text(item.message);
                            } else {
                                display.prepend(_alert('alert-danger', item.message));
                            }
                        }
                    });
                    if (count_error===0) {
                        _close(panel, progress);
                    }
                },
                error: function(jqXHR, textStatus, errorThrown){
                    _progress_set(0, false);
                    panel.find('button').attr('disabled', false);
                    display.prepend(_alert('alert-danger', '通信エラー（' + errorThrown + '）'));
                }
            });
        }

        /** @type {jQuery} Drag&Drop操作でファイルをドロップできる領域 */
        const _drop_mask = $('<div>').addClass('drop-mask').hide().css('position', 'absolute').css('top', '0').css('left', '0').css('width', '100%').css('height', '100%').css('border-radius', '4px').css('background-color', 'rgba(0,0,0,0.25)');
        /** @type {jQuery} */
        const _progress = $('<div>').addClass('progress-bar progress-bar-striped').attr('role', 'progressbar').attr('aria-valuemin', '0').attr('aria-valuemax', '100').attr('aria-valuenow', '0').css('width', '0%');
        /** @type {jQuery} */
        const _display = $('<div>').addClass('clearfix');
        /** @type {jQuery} */
        const _uploader_area = $('<div>').append(
                $('<input>').attr('type','file').attr('multiple',true).on('change', function(){
                    const input = $(this);
                    _uploader_prepair( _display, input.get(0).files );
                    input.replaceWith( input.val('').clone(true).hide() );
                }).on('click', function(){
                    $(this).hide();
                }).hide()
            ).append(
                $('<button>').addClass('btn btn-sm btn-default').append('<i class="fa-solid fa-paperclip"></i>').on('click', function(e){
                    e.preventDefault();
                    const input = $(this).parent().find('input[type="file"]');
                    /*
                     * Android 標準ブラウザで「不可視な input 要素は click イベントが動かない」のを回避。
                     * http://d.hatena.ne.jp/ukayare/20130214/1360843877
                     */
                    input.show().trigger('click');
                })
            ).append(
                $('<span/>').css('font-weight','bold').text(' ファイルを選択 または ここにドロップ（最大10個）')
            ).append(
                _display
            ).append(
                $('<div>').addClass('progress').append( _progress ).css('margin','10px 0 0 0')
            );
        /** @type {jQuery} */
        const _checkbox_thumb = $('<input>').attr('type', 'radio').attr('name', 'uploaded-image-display');
        /** @type {jQuery} */
        const _checkbox_big = $('<input>').attr('type', 'radio').attr('name', 'uploaded-image-display');
        /** @type {jQuery} */
        const _checkbox_link = $('<input>').attr('type', 'radio').attr('name', 'uploaded-image-display');
        /** @type {jQuery} */
        const _checkbox_html = $('<input>').attr('type', 'checkbox');

        _checkbox_thumb.prop('checked', true);

        /** @type {jQuery} パネル */
        const _panel = $('<div>').addClass('panel panel-primary').addClass('markdown-toolbox-dialog markdown-toolbox-dialog-upload').append(
            $('<div>').addClass('panel-heading').append(
                $('<h4>').addClass('panel-title').text(message)
            )
        ).append(
            $('<div>').addClass('panel-body').css('position', 'relative').append(
                _uploader_area.css('margin-bottom', '20px')
            ).append(
                $('<div>').addClass('pull-left text-left').append(
                    $('<div>').addClass('checkbox').css({
                        marginTop: 0,
                        marginLeft: -20
                    }).append(
                        $('<label>').append(_checkbox_thumb).append(' サムネイルリンク')
                    ).append(
                        $('<label>').append(_checkbox_link).append(' テキストリンク')
                    ).append(
                        $('<label>').append(_checkbox_big).append(' 本文上で最大サイズ表示')
                    )
                ).append(
                    $('<div>').addClass('checkbox').append(
                        $('<label/>').append(_checkbox_html).append(' HTMLタグ')
                    )
                )
            ).append(
                $('<div>').addClass('pull-right text-right').css({
                    whiteSpace: 'nowrap',
                    minWidth: 200
                }).append(
                    $('<button>').addClass('btn btn-sm btn-primary').css('margin-left', '5px').text('アップロード').on('click', function (e) {
                        e.preventDefault();
                        _uploader_upload(_panel, _progress, _display, _checkbox_big, _checkbox_link, _checkbox_html);
                    })
                ).append(
                    $('<button>').addClass('btn btn-sm btn-default').css('margin-left', '5px').text('閉じる').on('click', function (e) {
                        e.preventDefault();
                        _close(_panel, _progress);
                    })
                )
            ).append(
                _drop_mask.on('dragleave', function (e) {
                    e.preventDefault();
                    $(e.target).hide();
                }).on('dragenter', function (e) {
                    e.preventDefault();
                }).on('dragover', function (e) {
                    e.preventDefault();
                }).on('drop', function (e) {
                    e.preventDefault();
                    $(e.target).hide();
                    //noinspection JSUnresolvedVariable
                    _uploader_prepair(_display, e.originalEvent.dataTransfer.files);
                })
            ).on('dragenter', function () {
                _drop_mask.show();
            })
        ).hide().appendTo(dialogAreaSelector);

        return _button(size, tooltip, inner, function(e){
            e.preventDefault();
            if (_panel.is(':visible')) {
                $(dialogAreaSelector).find('>.markdown-toolbox-dialog').not(_panel).hide();
                _panel.slideUp();
            } else {
                // ウィンドウのスクロール量に影響しないよう高さを保持しつつ
                $(dialogAreaSelector).css('minHeight', _panel.height());
                $(dialogAreaSelector).find('>.markdown-toolbox-dialog').hide();
                _panel.slideDown(function(){
                    $(dialogAreaSelector).css('minHeight', '');
                });
            }
        });
    }

    /**
     * ドロップダウン・メニュー
     * @param {String} size
     * @param {String} tooltip
     * @param {String|jQuery} inner
     * @param {jQuery[]} listitems
     * @param {Number} width
     * @returns {jQuery}
     */
    function _dropdown(size, tooltip, inner, listitems, width) {
        const menu = $('<ul/>').addClass('dropdown-menu').attr('role', 'menu').css('width', width ? width + 'px' : '250px');
        if(width && width<160) menu.css('min-width',width+'px');
        $.each(listitems, function(idx, item){
            menu.append(item);
        });
        return $('<div>').addClass('btn-group dropup').append(
            $('<button>').addClass('dropdown-toggle btn btn-default').addClass(size)
                .attr('data-toggle', 'dropdown')
                .attr('type', 'button')
                .attr('tabindex', '-1')
                .attr('title', tooltip)
                .append(inner)
        ).append(menu);
    }

    /**
     * ドロップダウンの要素
     * @param {jQuery} textfields
     * @param {String} tooltip
     * @param {String|jQuery} inner
     * @param {Object} options
     * @returns {jQuery}
     */
    function _dropdown_item(textfields, tooltip, inner, options) {
        return $('<li>').append(
            $('<a/>').attr('href', '#').attr('title', tooltip)
            .append(inner)
            .click(function(e){
                e.preventDefault();
                _apply(textfields, options);
            })
        );
    }

    /**
     * CSS3の拡張色名（SVG カラー）
     * http://www.w3.org/TR/css3-color/#svg-color
     * @type {Array}
     */
    const _extended_color_list = [
        // 彩度 0% 明度昇順
        'black', 'dimgray', 'gray', 'darkgray', 'silver', 'lightgray', 'gainsboro', 'whitesmoke', 'white',
        // 明度 95% 以上
        'snow', 'seashell', 'floralwhite', 'ivory', 'honeydew', 'mintcream', 'azure', 'aliceblue', 'ghostwhite', 'lavenderblush',
        // 明度 90% 以上 95% 未満
        'mistyrose', 'linen', 'antiquewhite', 'blanchedalmond', 'papayawhip', 'oldlace', 'cornsilk', 'lemonchiffon', 'lightgoldenrodyellow', 'beige', 'lightyellow', 'lightcyan', 'lavender',
        // 明度 75% 以上 90% 未満
        'peachpuff', 'bisque', 'navajowhite', 'moccasin', 'wheat', 'palegoldenrod', 'palegreen', 'paleturquoise', 'powderblue', 'lightblue', 'lightskyblue', 'lightsteelblue', 'thistle', 'pink', 'lightpink',
        // 明度 62.5% 以上 75% 未満
        'rosybrown', 'lightcoral', 'salmon', 'tomato', 'darksalmon', 'coral', 'lightsalmon', 'sandybrown', 'burlywood', 'tan', 'khaki', 'darkseagreen', 'lightgreen', 'aquamarine', 'skyblue', 'cornflowerblue', 'mediumslateblue', 'mediumpurple', 'violet', 'plum', 'orchid', 'hotpink', 'palevioletred',
        // 明度 50% 以上 62.5% 未満
        'indianred', 'peru', 'darkkhaki', 'greenyellow', 'mediumaquamarine', 'turquoise', 'mediumturquoise', 'dodgerblue', 'slategray', 'lightslategray', 'royalblue', 'slateblue', 'blueviolet', 'mediumorchid', 'deeppink',
        // 明度 50%
        'red', 'orangered', 'darkorange', 'orange', 'gold', 'yellow', 'yellowgreen', 'chartreuse', 'limegreen', 'lime', 'springgreen', 'aqua', 'cyan', 'cadetblue', 'deepskyblue', 'blue', 'fuchsia', 'magenta',
        // 明度 35% 以上 50% 未満
        'brown', 'firebrick', 'sienna', 'chocolate', 'darkgoldenrod', 'goldenrod', 'lawngreen', 'seagreen', 'mediumseagreen', 'mediumspringgreen', 'lightseagreen', 'darkturquoise', 'steelblue', 'mediumblue', 'darkslateblue', 'darkorchid', 'darkviolet', 'mediumvioletred', 'crimson',
        // 明度 35% 未満
        'maroon', 'darkred', 'saddlebrown', 'olive', 'olivedrab', 'darkolivegreen', 'darkgreen', 'green', 'forestgreen', 'darkslategray', 'teal', 'darkcyan', 'navy', 'midnightblue', 'darkblue', 'indigo', 'purple', 'darkmagenta'
    ];

    /**
     * CSS3の基本色名（16個）
     * http://www.w3.org/TR/css3-color/#html4
     * @type {String[]}
     */
    const _color_list = ['black', 'silver', 'gray', 'white', 'maroon', 'red', 'purple', 'fuchsia', 'green', 'lime', 'olive', 'yellow', 'navy', 'blue', 'teal', 'aqua'];

    /**
     * 色選択ボタンを列挙したdiv要素
     * @param {function(string)} callback
     * @returns {jQuery}
     */
    function _color_palette(callback) {
        const palette = $('<div>')
            .css('margin', '0 5px')
            .css('line-height', '24px');

        function appendColorButton(idx, color) {
            palette.append(
                $('<button>')
                    .attr('type', 'button')
                    .attr('tabindex', '-1')
                    .css('background-color', color)
                    .css('display', 'inline-block')
                    .css('padding', '0')
                    .css('margin', '1px')
                    .css('border', '0px none')
                    .css('width', '22px')
                    .css('height', '22px')
                    .css('vertical-align', 'bottom')
                    .css('box-shadow', '1px 1px 1px rgba(0, 0, 0, 0.4)')
                    .attr('title', color)
                    //.attr('data-toggle','tooltip') // TOOLTIP DISABLED
                    .attr('data-placement','auto')
                    .click(function(e){
                        e.preventDefault();
                        callback(color);
                    })
            );
        }

        palette.append($('<div>').css('margin', '1px').text('CSS3 拡張色'));
        $.each(_extended_color_list, appendColorButton);

        palette.append($('<div>').css('margin', '1px').text('CSS3 基本16色'));
        $.each(_color_list, appendColorButton);

        return palette;
    }

    /**
     * プレビューを実行するボタン
     * @param {jQuery} textfield
     * @param {String} size
     * @param {function} previewFunction
     * @param {String} dialogAreaSelector
     * @returns {jQuery}
     */
    function _preview(textfield, size, previewFunction, dialogAreaSelector) {
        const preview_div = $('<div>')
            .addClass('body');  // CSSを当てるためにクラスを付ける
        const preview_frame = $('<div>')
            .addClass('panel panel-default markdown-toolbox-dialog markdown-toolbox-dialog-preview')
            .append(
                $('<div>').addClass('panel-heading').append(
                    $('<button>')
                        .addClass('btn btn-xs btn-default pull-right')
                        .attr('tabindex', '-1')
                        .attr('title', 'プレビューを閉じる')
                        .append('<i class="fa-solid fa-xmark"></i>')
                        .click(function (e) {
                            e.preventDefault();
                            preview_frame.slideUp();
                        })
                ).append('プレビュー表示')
            ).append(
                $('<div>').addClass('panel-body').append(
                    $('<div>').addClass('content').append(preview_div)
                )
            ).hide().appendTo(dialogAreaSelector);

        function preview() {
            preview_div.html(
                $('<span>').css('color', 'gray').append('<i class="fa-solid fa-arrows-rotate fa-spin"></i>').append(' プレビューを生成中です...')
            );

            previewFunction(textfield.val(), function(convertedHtml) {
                if (convertedHtml === null){
                    preview_div.html("");
                    preview_frame.slideUp();
                    return;
                }
                preview_div.html( convertedHtml );
                preview_div.find('.emoji-text').emojify();
                preview_div.find('.paragraph-collapse').paragraphCollapse();
                preview_div.find('div[data-lazy-widget]').lazyLoadXT();
                //preview_div.find('.comment-parent-link').expandCommentRef(); // できるけど適用してない

                if (!!window.lazyload_fix) {
                    window.lazyload_fix.prepare(preview_div); // in lazy-load-fix.js
                    window.lazyload_fix.done(preview_div); // in lazy-load-fix.js
                }

                // PR 中 https://github.com/PrismJS/prism/pull/1230
                prismHighlightUnderElement(preview_div[0]);

                //noinspection JSValidateTypes
                $(window).scrollTop($(window).scrollTop()+1); // YouTube などの lazyLoadXT() 適用要素を描画させる
            });
        }

        // PR 中 https://github.com/PrismJS/prism/pull/1230
        function prismHighlightUnderElement(parent, async, callback) {
            const env = {
                callback: callback,
                selector: 'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code'
            };
            Prism.hooks.run("before-highlightall", env);
            const elements = env.elements || parent.querySelectorAll(env.selector);
            // noinspection JSAssignmentUsedAsCondition
            for (let i=0, element; element = elements[i++];) {
                Prism.highlightElement(element, async === true, env.callback);
            }
        }

        textfield.on('keyup', function(e){
            if (e.keyCode === 80 && e.ctrlKey ){ // ctrl + p
                preview();
            }
        });

        return _button(size, 'プレビュー', 'プレビュー', function(e){
            e.preventDefault();
            if (preview_frame.is(':hidden')) {
                // ウィンドウのスクロール量に影響しないよう高さを保持しつつ
                $(dialogAreaSelector).css('minHeight', preview_frame.height());
                $(dialogAreaSelector).find('>.markdown-toolbox-dialog').hide();
                preview_frame.slideDown(function(){
                    $(dialogAreaSelector).css('minHeight', '');
                });
            }
            preview();
        });
    }

    /**
     * フルスクリーン化を実行するボタン
     * @param {jQuery} textfield
     * @param {String} size
     * @param {String} dialogAreaSelector
     * @returns {jQuery}
     * @private
     */
    function _fullscreen(textfield, size, dialogAreaSelector){
        const data_key_old_scroll_top = 'fullscreen-old-scroll-top';
        const data_key_old_rows = 'fullscreen-old-rows';
        const data_key_was_auto_size = 'fullscreen-was-auto-size';
        const data_key_isolated_handler = 'fullscreen-isolated-handler';
        const keycode_escape = 27;
        const event_keyup = 'keyup.fullscreen';
        const event_resize = 'resize.fullscreen';
        const block_class_fullscreen = 'fullscreen';
        const body_class_fullscreen_editor = 'fullscreen-editor';
        const button_class_fullscreen_close = 'fullscreen-close';
        const fullscreenToggler = function () {
            const $body = $('body');
            const $toolbox = textfield.parent().find('.markdown_toolbox');
            const $preview = $(dialogAreaSelector);
            if (!textfield.is('.' + block_class_fullscreen)) {
                // フルスクリーン終了時に使う値を保存
                $body.data(data_key_old_scroll_top, $body.scrollTop());
                textfield.data(data_key_old_rows, textfield.attr('rows'));
                if (textfield.is('.auto-size')) {
                    textfield.data(data_key_was_auto_size, true).removeClass('auto-size');
                    textfield.css('height', 'auto');
                }
                // フルスクリーンにする
                $body.addClass(body_class_fullscreen_editor);
                $toolbox.addClass(block_class_fullscreen);
                $preview.addClass(block_class_fullscreen);
                textfield.addClass(block_class_fullscreen);

                $('<button>').addClass('btn btn-default ' + button_class_fullscreen_close).append('<i class="fa-solid fa-xmark fa-lg"></i>').on('click', function () {
                    fullscreenToggler();
                }).appendTo($body);

                // 二段目ツールバーを強制表示
                $toolbox.find('.btn-toolbar:not(:visible)').show();

                $(window).on(event_resize, function () {
                    const windowHeight = $(window).height();
                    const windowWidth = $(window).width();
                    $toolbox.css('height', 'auto');
                    const toolboxHeight = $toolbox.outerHeight(true);
                    $toolbox.css('height', toolboxHeight + 'px');
                    if (windowWidth >= 480) {
                        textfield.css('bottom', toolboxHeight + 'px');
                        textfield.css('height', (windowHeight - toolboxHeight) + 'px');
                        $preview.css('margin-top', '0');
                    } else {
                        textfield.css('bottom', '50%');
                        textfield.css('height', (windowHeight / 2) + 'px');
                        $preview.css('margin-top', toolboxHeight + 'px');
                    }
                }).trigger(event_resize);

                $(window).on(event_keyup, function (e) {
                    if (e.keyCode === keycode_escape) {
                        fullscreenToggler();
                    }
                });

                // jquery.textarea_autosize.js と動作がかち合う。
                // フルスクリーン中だけ、該当の（input と keyup に登録されている）イベントハンドラを off して隔離しておく。
                const _handlers = $._data(textfield.get(0)).events;
                if ($.isArray(_handlers.input) && $.isArray(_handlers.keyup)) {
                    $.each(_handlers.input, function (_, _input) {
                        $.each(_handlers.keyup, function (_, _keyup) {
                            if (_input && _input.hasOwnProperty('handler') && typeof _input.handler === 'function' &&
                                _keyup && _keyup.hasOwnProperty('handler') && typeof _keyup.handler === 'function' &&
                                (_input.handler === _keyup.handler || _input.guid === _keyup.guid)
                            ) {
                                textfield.off({
                                    'input': _input.handler,
                                    'keyup': _keyup.handler
                                }).data(data_key_isolated_handler, _input.handler);
                            }
                        });
                    });
                }
            } else {
                $body.removeClass(body_class_fullscreen_editor).scrollTop($body.data(data_key_old_scroll_top));
                $toolbox.removeClass(block_class_fullscreen).css('height', 'auto');
                $preview.removeClass(block_class_fullscreen).css('height', 'auto');
                textfield.removeClass(block_class_fullscreen).css('height', 'auto').attr('rows', textfield.data(data_key_old_rows));
                if (!!textfield.data(data_key_was_auto_size)) {
                    textfield.addClass('auto-size');
                }
                $body.find('.' + button_class_fullscreen_close).remove();
                $(window).off(event_keyup).off(event_resize);

                // 隔離してたイベントハンドラを元に戻す
                const handler = textfield.data(data_key_isolated_handler);
                if (handler && typeof handler === 'function') {
                    textfield.on('input keyup', handler).trigger('input');
                }
            }
            textfield.focus();
        };
        return _simple_button(size, null, 'フルスクリーン', '<i class="fa-solid fa-maximize"></i>', fullscreenToggler);
    }


    // --------------- 複数ブラウザ対応オブジェクト ---------------

    //noinspection JSUnresolvedVariable
    const createObjectURL = (window.URL && window.URL.createObjectURL) ? function(file) {
        //noinspection JSUnresolvedFunction,JSUnresolvedVariable
        return window.URL.createObjectURL(file);
    } : (window.webkitURL && window.webkitURL.createObjectURL) ? function(file) {
        //noinspection JSUnresolvedFunction,JSUnresolvedVariable
        return window.webkitURL.createObjectURL(file);
    } : undefined;

    // --------------- 処理本体 ---------------

    /**
     * 処理を適用する。
     *
     * @param {Object} textfield            操作対象となる input または textarea のjQueryオブジェクト。
     * @param {Object} options              適用する処理の設定。
     * @param {String} options.before       前に挿入する文字列。
     * @param {String} options.after        後に挿入する文字列。
     * @param {String} options.placeholder  選択文字がない場合に代わりに使う文字列。
     * @param {Boolean|undefined} options.eachLine    複数行対象の場合に各行ごとに処理を行うかどうか。
     * @param {Boolean|undefined} options.unselect    処理完了後に選択を解除するかどうか。
     * @param {Object|undefined} options.shiftSelection  最後に選択範囲を移動させる量
     */
    function _apply(textfield, options) {
        let temp_position = textfield.selection('getPos');
        if (options.before && !options.after) {
            // 選択範囲をキャレットのある行全体にする。
            let left = temp_position.start;
            let right = temp_position.end;
            while(left > 0) {
                const cl = textfield.val().charAt(left - 1);
                if (cl !== '\n' && cl !== '\r') {
                    left--;
                } else {
                    break;
                }
            }
            while(right < textfield.val().length) {
                const cr = textfield.val().charAt(right);
                if (cr !== '\n' && cr !== '\r') {
                    right++;
                } else {
                    break;
                }
            }
            if (left !== temp_position.start || right !== temp_position.end) {
                temp_position = {start:left, end:right};
                textfield.selection('setPos', temp_position);
            }
        }

        let temp_text = textfield.selection('get');
        if (!temp_text && options.placeholder) {
            temp_text = options.placeholder;
        }
        if (options.before) {
            if (options.eachLine) {
                temp_text = temp_text.replace(new RegExp('^','gm'), options.before);
            } else {
                temp_text = options.before + temp_text;
            }
        }
        if (options.after) {
            if (options.eachLine) {
                temp_text = temp_text.replace(new RegExp('$','gm'), options.after);
            } else {
                temp_text = temp_text + options.after;
            }
        }
        temp_position.end = temp_position.start + temp_text.length;
        if (options.unselect) {
            temp_position.start = temp_position.end;
        }
        if (options.hasOwnProperty('shiftSelection')) {
            if (options.shiftSelection.hasOwnProperty('start')) {
                temp_position.start += Number(options.shiftSelection.start);
            }
            if (options.shiftSelection.hasOwnProperty('end')) {
                temp_position.end += Number(options.shiftSelection.end);
            }
        }
        textfield.selection('replace', {text:temp_text});
        textfield.selection('setPos', temp_position);

        textfield.keyup();
    }

    /**
     *
     * @param {jQuery} textfield
     * @param {int} maxHistory
     */
    function interceptUndoRedo(textfield, maxHistory) {

        const ua = window.navigator.userAgent;
        if (ua.indexOf('Mobile') > 0 ||
            ua.indexOf('iPhone') > 0 ||
            ua.indexOf('Android') > 0 ||
            ua.indexOf('iPad') > 0  ) {
            // Ctrlキー押せないであろうデバイスでは無効化しておく。
            return;
        }

        const undoBuffer = [textfield.val()];
        let redoBuffer = [];

        const remember = function () {
            const previous = (undoBuffer.length > 0) ? undoBuffer[undoBuffer.length - 1] : '';
            const latest = textfield.val();
            if (previous.trim() !== latest.trim()) {
                undoBuffer.push(latest);
                redoBuffer = [];
                if (undoBuffer.length > maxHistory) {
                    undoBuffer.shift();
                }
            }
        };
        const undo = function() {
            if (undoBuffer.length > 0) {
                redoBuffer.push(undoBuffer.pop());
                textfield.val(undoBuffer[undoBuffer.length - 1]);
            }
        };
        const redo = function () {
            if (redoBuffer.length > 0) {
                const v = redoBuffer.pop();
                textfield.val(v);
                undoBuffer.push(v);
            }
        };

        textfield.on({
            'keydown': function(ev){
                if (!!ev && !!ev.originalEvent) {
                    const o = ev.originalEvent;
                    if (
                        (!o.shiftKey && !o.metaKey && o.ctrlKey && o.key === 'z') ||
                        (!o.shiftKey && o.metaKey && !o.ctrlKey && o.key === 'z')
                    ) {
                        undo();
                        ev.preventDefault();
                        return false;
                    } else if (
                        (!o.shiftKey && !o.metaKey && o.ctrlKey && o.key === 'y') ||
                        (o.shiftKey && o.metaKey && !o.ctrlKey && o.key === 'z')
                    ) {
                        redo();
                        ev.preventDefault();
                        return false;
                    }
                }
            },
            'keyup': function(ev) {
                if (!!ev && !!ev.originalEvent) {
                    const o = ev.originalEvent;
                    if (!o.isComposing && !o.ctrlKey && !o.metaKey && !o.shiftKey && !o.altKey
                        && o.key !== 'Control' && o.key !== 'Meta' && o.key !== "Shift" && o.key !== "Alt") {
                        // IME変換中を除く有意なキー
                        remember();
                    }
                } else {
                    // _apply() から発火のケース
                    remember();
                }
            },
            'focusout': function() {
                remember();
            }
        });
    }

})(jQuery);
